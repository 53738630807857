<template>
  <div class="flex-col page">
    <Wapheader />
    <div class="flex-col space-y-58">
      <div class="flex-col">
        <div class="flex-col group_37">
          <img
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16633159376539736669.png"
            class="image_23" />
          <div class="flex-col space-y-1074 group_37">
            <div style="opacity: 0;" class="flex-row justify-between section_2">
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/7aac57f92046566f1b38145db82c5d21.png"
                class="image" />
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16626005966223060878.png"
                class="image_1" />
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16626005966407546710.png"
                class="image_2" />
            </div>
            <div class="flex-row justify-center space-x-45 section_3">
              <div class="flex-col items-center text-wrapper"><span class="text" @click="navPath('/newwapyy')">预约试驾</span></div>
              <div class="flex-col items-center text-wrapper_1"><span class="text_1">购买商城</span></div>
            </div>
          </div>
        </div>
        <div class="flex-col items-center section_4">
          <div class="group_2">
            <span class="text_2">
              移动生活美学
              <br />
            </span>
            <span class="text_3">
              从i-OneX启程
              <br />
            </span>
            <span class="text_4"><br /></span>
            <span class="text_5">
              纯正摩托血统
              <br />
            </span>
            <span class="text_6">
              淬炼优异性能
              <br />
            </span>
            <span class="text_7">城市，就是我的主场</span>
          </div>
        </div>
        <div class="flex-col section_5">
          <div class="flex-col space-y-69 section_6">
            <span class="text_8">定义全新未来风潮</span>
            <div class="grid">
              <div class="flex-col items-end space-y-25 grid-item">
                <div>
                  <span class="text_9">65</span>
                  <span class="text_10">km</span>
                </div>
                <span class="text_11" >等速续航</span>
              </div>
              <div class="flex-col items-start space-y-25 grid-item_1">
                <div>
                  <span class="text_12">49</span>
                  <span class="text_13">km/h</span>
                </div>
                <span class="text_14">极速</span>
              </div>
              <div class="flex-col items-end space-y-25 grid-item_2">
                <div>
                  <span class="text_15">1.5</span>
                  <span class="text_16">kw</span>
                </div>
                <span class="text_17">最大功率</span>
              </div>
              <div class="flex-col items-center space-y-24 grid-item_3" style="margin-left:-30px;">
                <span class="text_18">21700</span>
                <span class="text_19" style="margin-top:17px;">电芯规格</span>
              </div>
            </div>
            <div class="flex-row justify-center section_7">
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16633159375452389091.png"
                class="image_3" />
              <span class="text_20">观看完整影片</span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-col items-start space-y-31 section_8">
        <div>
          <span class="text_21">
            耐看型选手
            <br />
          </span>
          <span class="text_22">颜值始终在线</span>
        </div>
        <div class="group_7">
          <span class="text_23">
            富有韵律的线条有节奏地交错流动，
            <br />
          </span>
          <span class="text_24">
            构建成参数化肌理前面板，
            <br />
          </span>
          <span class="text_25">车规级LED标志灯给人耳目一新的未来感。</span>
        </div>
      </div>
      <div class="flex-col space-y-47 group_8">
        <div class="flex-col space-y-42 section_9">
          <span class="text_26">留下帅气的背影</span>
          <span class="text_27">
            反曲面腰身设计衔接大溜背尾部轮廓，搭配熔岩科技尾灯组，为车身营造更具科技感的视觉感受，潮流气场再度升级。
            <br />
          </span>
        </div>
        <div class="flex-col section_10">
          <span class="text_28">做足“表面功夫”</span>
          <span class="text_29">
            全车喷涂的汽车级环保水性漆具有耐划伤 、耐污染、超耐UV光照老化的特点，色泽华丽柔和持久如新。
            <br />
          </span>
        </div>
      </div>
    </div>
    <span class="text_30">只能自己体会的细节</span>
    <div class="flex-row space-x-32 group_9">


      <div class="swiper swiper1">
        <div class="swiper-wrapper">
          <div v-for="(t,i) in list" class="swiper-slide">

            <div class="flex-col items-start section_11">
              <img :src="require(`@/assets/images/ionex/${i+1}.png`)" class="image_25" />
              <div class="flex-col space-y-16 group_36">
                <span class="text_31" v-html="t.title">数码微动开关</span>
                <div v-html="t.content" class="group_10">

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-col items-center image-wrapper">
          <img
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16633159372624244233.png"
            class="image_4" />
        </div>
      </div>



    </div>
    <div class="flex-col items-center section_13">
      <div class="group_12">
        <span class="text_37">
          12项车规级技术
          <br />
        </span>
        <span class="text_38">增程锂电池</span>
      </div>
    </div>
    <div class="flex-col space-y-18 group_13 view">
      <span class="text_39">面面俱到的安全保护</span>
      <span class="text_40">具备IP67的防水等级，当电池温度过高时，主动热失控检测防护系统即刻自动断电。</span>
    </div>
    <div class="flex-col space-y-18 group_14">
      <span class="text_41">值得一提的内在功夫</span>
      <span class="text_42">锂电芯呈蜂巢式排列搭配，保证高导电效率的同时还将获得单体电芯熔丝保护。</span>
    </div>
    <div class="flex-col space-y-20 group_15">
      <div class="flex-row space-x-10">
        <span class="text_43">无限续航</span>
        <span class="text_44">即刻焕新</span>
      </div>
      <span class="text_45">
        全系标配租电服务，缓解里程焦虑，在换电站每颗更换的电池都是健康的，无需承担电池损耗的寿命。
      </span>
    </div>
    <div class="flex-col items-center text-wrapper_2"><span class="text_46" @click="navPath('/newwapcnw')">了解车能网</span></div>


    <van-swipe class="my-swipe" :autoplay="3000">
      <van-swipe-item>
        <div class="flex-col section_14">
          <span class="text_47">大材小用，各尽骑用</span>
          <div class="flex-col items-center space-y-20 group_17">
            <span class="group_13 text_48">
              每个车部件都来自摩企60年制造工艺沉淀，全面应用最新智能制造系统，从零件到整车，都在机器人工作站的标准化监控下作业，高效且有品质保障。
            </span>
            <img
              src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16633159370368704924.png"
              class="image_5" />
          </div>
          <div class="flex-col space-y-32 group_18">
            <span class="text_49">环抱式车架结构</span>
            <div class="group_19">
              <span class="text_50">
                KYMCO大排量踏板摩托车同材铬钼钢管，
                <br />
              </span>
              <span class="text_51">
                经过160万次应力及SHOCK测试，
                <br />
              </span>
              <span class="text_52">车身具备更大的强度和刚性。</span>
            </div>
          </div>
          <div class="flex-row justify-center space-x-39 group_20">
            <div class="section_15">
              <!---->
            </div>
            <div class="section_16">
              <!---->
            </div>
          </div>
        </div>
      </van-swipe-item>
      <van-swipe-item>
        <div class="flex-col section_14 section_14New">
          <span class="text_47">大材小用，各尽骑用</span>
          <div class="flex-col items-center space-y-20 group_17">
            <span class="group_13 text_48">
              每个车部件都来自摩企60年制造工艺沉淀，全面应用最新智能制造系统，从零件到整车，都在机器人工作站的标准化监控下作业，高效且有品质保障。
            </span>
            <img
              src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16633159370368704924.png"
              class="image_5" />
          </div>
          <div class="flex-col space-y-32 group_18">
            <span class="text_49">摩托车级液压减震</span>
            <div class="group_19">
              <span class="text_50">
                KYMCO大排量踏板摩托车同材铬钼钢管，
                <br />
              </span>
              <span class="text_51">
                经过160万次应力及SHOCK测试，
                <br />
              </span>
              <span class="text_52">车身具备更大的强度和刚性。</span>
            </div>
          </div>
          <div class="flex-row justify-center space-x-39 group_20">
            <div class="section_15" style="background-color:#ffffff5c">
              <!---->
            </div>
            <div class="section_16" style="background-color:#fff">
              <!---->
            </div>
          </div>
        </div>
      </van-swipe-item>
    </van-swipe>
    <constart car="ionex"></constart>
    <Wapfooter />
  </div>
</template>

<script>
import constart from "../../components/Newconstart/index.vue"
import { Swipe, SwipeItem } from 'vant';
export default {
  components: {
    constart,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  data() {
    return {
      list: [
        {
          title: "数码微动开关", content: `键程长短适中反馈清晰舒适<br>
直视前方也可清晰操作功能键`},
        {
          title: "自动感应大灯", content: `随夜幕降临自动闪耀<br>
每天回家的路始终清楚明亮`,
          // title: "全新升级多彩智能仪表",
          // content: "精准到每一位数，更智能更数字化"

        },
        {
          title: "全新升级多彩智能仪表",
          content: "精准到每一位数，更智能更数字化"
        },
        {
          title: "摩托级悬吊<br>前刹车系统",
          content: "激烈行进间使用刹车<br>仍能保持完美动作"
        },
        {
          title: "轻量化铝合金锻造轮毂",
          content: "重量轻且耐高压<br>转弯轻快灵活易操控"
        }
      ]
    };
  },
  methods: {},
  mounted() {
    new Swiper('.swiper1', {
      slidesPerView: 1,
      spaceBetween: -70,
      loop: true,
      navigation: {
        nextEl: '.image_4',

      },

    })
  },

};
</script>
<style>
.van-swipe__track {
  display: flex !important;
}
</style>
<style scoped lang="css">
.section_14New {
  background-image: url(../../assets/images/ionex/sj/banner.png) !important;
}

.page {

  /* background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16633159356061615060.png'); */
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.space-y-58>*:not(:first-child) {
  margin-top: 58px;
}

.group_37 {
  position: relative;
}

.image_23 {
  width: 750px;
  height: 1304.00025px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
}

.space-y-1074>*:not(:first-child) {
  margin-top: 1074px;
}

.section_2 {
  padding: 30px 30px 29px;
  background-color: #00000126;
}

.image {
  margin-bottom: 3px;
  width: 40px;
  height: 36px;
}

.image_1 {
  width: 260px;
  height: 41px;
}

.image_2 {
  width: 40px;
  height: 40px;
}

.space-x-45>*:not(:first-child) {
  margin-left: 45px;
}

.section_3 {
  padding: 39px 140px 39px;
  background-color: #ffffff;
}

.text-wrapper {
  padding: 18px 0 16px;
  border-radius: 30px;
  width: 212px;
  height: 61px;
  border: solid 2px #000000;
}

.text {
  color: #000;
  font-size: 24px;
  font-family: 'Albbr';
  line-height: 23px;
}

.text-wrapper_1 {
  padding: 20px 0 18px;
  background-color: #007bff;
  border-radius: 30px;
  width: 212px;
  height: 61px;
}

.text_1 {
  color: #ffffff;
  font-size: 24px;
  font-family: 'Albbr';
  line-height: 23px;
}

.section_4 {
  padding: 148px 0 863px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16633159375913899230.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.group_2 {
  text-align: center;
  width: 379px;
  font-family: 'Albb';
}

.text_2 {
  color: #ffffff;
  font-size: 42px;

  font-weight: 700;
  line-height: 54px;
}

.text_3 {
  color: #ffffff;
  font-size: 42px;

  font-weight: 700;
  line-height: 54px;
}

.text_4 {
  color: #ffffff;
  font-size: 42px;

  font-weight: 700;
  line-height: 54px;
}

.text_5 {
  color: #ffffff;
  font-size: 42px;

  font-weight: 700;
  line-height: 54px;
}

.text_6 {
  color: #ffffff;
  font-size: 42px;

  font-weight: 700;
  line-height: 54px;
}

.text_7 {
  color: #ffffff;
  font-size: 42px;

  font-weight: 700;
  line-height: 54px;
}

.section_5 {
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16633159374452066420.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.space-y-69>*:not(:first-child) {
  margin-top: 69px;
}

.section_6 {
  padding: 124px 62px 87px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16631458482881337186.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.text_8 {
  align-self: center;
  color: #ffffff;
  font-size: 48px;
  font-family: 'Albb';
  line-height: 45px;
}

.grid {
  height: 336px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.space-y-25>*:not(:first-child) {
  margin-top: 25px;
}

.grid-item {
  padding: 39px 66px 38px;
}

.text_9 {
  color: #ffffff;
  font-size: 60px;

  font-weight: 700;
  line-height: 43px;
}

.text_10 {
  color: #ffffff;
  font-size: 36px;

  line-height: 28px;
}

.text_11 {
  margin-right: 23px;
  color: #ffffff;
  font-size: 24px;

  line-height: 23px;
}

.grid-item_1 {
  padding: 38px 48px;
}

.text_12 {
  color: #ffffff;
  font-size: 60px;

  font-weight: 700;
  line-height: 44px;
}

.text_13 {
  color: #ffffff;
  font-size: 36px;

  line-height: 29px;
}

.text_14 {
  margin-left: 38px;
  color: #ffffff;
  font-size: 24px;

  line-height: 23px;
}

.grid-item_2 {
  padding: 38px 61px 39px;
}

.text_15 {
  color: #ffffff;
  font-size: 60px;

  font-weight: 700;
  line-height: 43px;
}

.text_16 {
  color: #ffffff;
  font-size: 36px;

  line-height: 28px;
}

.text_17 {
  margin-right: 27px;
  color: #ffffff;
  font-size: 24px;

  line-height: 23px;
}

.space-y-24>*:not(:first-child) {
  margin-top: 24px;
}

.grid-item_3 {
  padding: 38px 0 39px;
}

.text_18 {
  color: #ffffff;
  font-size: 60px;

  font-weight: 700;
  line-height: 44px;
}

.text_19 {
  color: #ffffff;
  font-size: 24px;

  line-height: 23px;
}

.section_7 {
  padding: 20px 48px 20px 50px;
  align-self: center;
  background-color: #ffffff;
  border-radius: 35px;
  width: 300px;
}

.image_3 {
  width: 30px;
  height: 30px;
}

.text_20 {
  margin: 3px 0 4px 21px;
  color: #000000;
  font-size: 24px;
  font-family: 'Albbm';
  line-height: 23px;
  letter-spacing: 1.2px;
}

.space-y-31>*:not(:first-child) {
  margin-top: 31px;
}

.section_8 {
  padding: 633px 80px 57px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16633159372854294373.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.text_21 {
  color: #ffffff;
  font-size: 48px;
  font-family: 'Albb';
  line-height: 60px;
}

.text_22 {
  color: #ffffff;
  font-size: 48px;
  font-family: 'Albb';
  line-height: 60px;
}

.group_7 {
  line-height: 36px;
}

.text_23 {
  color: #ffffff;
  font-size: 28px;
  font-family: 'Albbr';
  line-height: 36px;
}

.text_24 {
  color: #ffffff;
  font-size: 28px;
  font-family: 'Albbr';
  line-height: 36px;
}

.text_25 {
  color: #ffffff;
  font-size: 28px;
  font-family: 'Albbr';
  line-height: 36px;
}

.space-y-47>*:not(:first-child) {
  margin-top: 47px;
}

.group_8 {
  padding-left: 30px;
  padding-right: 29px;
}

.space-y-42>*:not(:first-child) {
  margin-top: 42px;
}

.section_9 {
  padding: 120px 38px 475.5px 50px;
  filter: drop-shadow(0px 14px 35px #00000030);
  border-radius: 50px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16633159374265310718.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.text_26 {
  align-self: flex-start;
  color: #000;
  font-size: 48px;
  font-family: 'Albb';
  line-height: 45px;
}

.text_27 {
  color: #000;
  font-size: 28px;
  font-family: 'Albbr';
  line-height: 37px;
}

.section_10 {
  padding: 118px 42px 475.5px;
  filter: drop-shadow(0px 14px 35px #00000030);
  border-radius: 50px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16633159373550012553.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.text_28 {
  align-self: flex-start;
  color: #000;
  font-size: 48px;
  font-family: 'Albb';
  line-height: 48px;
}

.text_29 {
  margin: 41.5px auto;
  color: #000;
  font-size: 28px;
  font-family: 'Albbr';
  line-height: 37px;
}

.text_30 {
  margin-top: 179px;
  align-self: center;
  color: #000;
  font-size: 60px;
  font-family: 'Albb';
  line-height: 56px;
}

.space-x-32>*:not(:first-child) {
  margin-left: 32px;
}

.group_9 {
  margin-top: 85px;
  padding-left: 79px;
}

.section_11 {
  padding: 55px 0 443px;
  flex: 1 1 auto;

  border-radius: 50px;
  height: 608px;
  position: relative;
}

.image_25 {

  border-radius: 50px;
  width: 488px;
  height: 608px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
}

.space-y-16>*:not(:first-child) {
  margin-top: 16px;
}

.group_36 {
  margin-left: 39px;
  /* width: 248px; */
  position: relative;
}

.text_31 {
  color: #000;
  font-size: 39px;
  font-family: 'Albb';

}

.group_10 {
  font-family: 'Albbr';
  font-size: 19.45px;
}

.text_32 {
  color: #000;
  font-size: 19px;
  font-family: 'Albbr';
  line-height: 29px;
}

.text_33 {
  color: #000;
  font-size: 19px;
  font-family: 'Albbr';
  line-height: 29px;
}

.section_12 {
  padding: 54px 0 214px;
  flex-shrink: 0;
  filter: drop-shadow(0px 14px 35px #00000030);
  border-radius: 50px 0px 0px 50px;
  width: 151px;
  height: 608px;
  position: relative;
}

.image_24 {
  filter: drop-shadow(0px 14px 35px #00000030);
  border-radius: 50px 0px 0px 50px;
  width: 151px;
  height: 608px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
}

.view_2 {
  margin-left: 38px;
}

.text_34 {
  margin-left: 4px;
  color: #000;
  font-size: 39px;
  font-family: 'Albb';
  line-height: 37px;
}

.group_11 {
  margin-top: 16px;
  align-self: flex-start;
  line-height: 29px;
}

.text_35 {
  color: #000;
  font-size: 19px;
  font-family: 'Albbr';
  line-height: 29px;
}

.text_36 {
  color: #000;
  font-size: 19px;
  font-family: 'Albbr';
  line-height: 29px;
}

.image-wrapper {
  margin-right: 19.5px;
  margin-top: 49px;
  padding: 68px 0 67px;
  align-self: flex-end;
  background-color: #2e8ac9;
  width: 60px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: auto;
  z-index: 999;
}

.image_4 {
  width: 25px;
  height: 45px;
}

.section_13 {
  margin-top: 289px;
  padding: 149px 0 707px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16633159370412968753.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.group_12 {
  text-align: center;
}

.text_37 {
  color: #000;
  font-size: 60px;
  font-family: 'Albb';
  line-height: 72px;
}

.text_38 {
  color: #000;
  font-size: 60px;
  font-family: 'Albb';
  line-height: 72px;
}

.space-y-18>*:not(:first-child) {
  margin-top: 18px;
}

.group_13 {
  width: 601px;
}

.view {
  margin-top: 109px;
  align-self: center;
}

.text_39 {
  align-self: flex-start;
  color: #000;
  font-size: 36px;
  font-family: 'Albb';
  line-height: 36px;
}

.text_40 {
  color: #747474;
  font-size: 28px;
  font-family: 'Albbr';
  line-height: 36px;
}

.group_14 {
  margin-top: 77px;
  align-self: center;
  width: 599px;
}

.text_41 {
  align-self: flex-start;
  color: #000;
  font-size: 36px;
  font-family: 'Albb';
  line-height: 36px;
}

.text_42 {
  color: #747474;
  font-size: 28px;
  font-family: 'Albbr';
  line-height: 36px;
}

.space-y-20>*:not(:first-child) {
  margin-top: 20px;
}

.group_15 {
  margin-top: 73px;
  align-self: center;
  width: 602px;
}

.space-x-10>*:not(:first-child) {
  margin-left: 10px;
}

.text_43 {
  color: #000;
  font-size: 36px;
  font-family: 'Albb';
  line-height: 36px;
}

.text_44 {
  color: #000;
  font-size: 36px;
  font-family: 'Albb';
  line-height: 36px;
}

.text_45 {
  color: #747474;
  font-size: 28px;
  font-family: 'Albbr';
  line-height: 36px;
}

.text-wrapper_2 {
  margin-top: 65px;
  padding: 22px 0 20px;
  align-self: center;
  border-radius: 35.5px;
  width: 251px;
  border: solid 2px #000000;
}

.text_46 {
  color: #000;
  font-size: 28px;
  font-family: 'Albbr';
  line-height: 26px;
}

.section_14 {
  margin-top: 233px;
  padding: 182px 30px 97px;
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/610921c9d22a310012d85fe8/62fb5073689f2800114f1f25/16633159367023234998.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.text_47 {
  align-self: center;
  color: #ffffff;
  font-size: 60px;
  font-family: 'Albb';
  line-height: 58px;
}

.group_17 {
  margin-top: 49px;
}

.text_48 {
  color: #ffffff;
  font-size: 28px;
  font-family: 'Albbr';
  line-height: 42px;
}

.image_5 {
  width: 690px;
  height: 416.00025px;
}

.space-y-32>*:not(:first-child) {
  margin-top: 32px;
}

.group_18 {
  margin-top: 90px;
  align-self: center;
  width: 507px;
}

.text_49 {
  align-self: center;
  color: #ffffff;
  font-size: 36px;
  font-family: 'Albb';
  line-height: 36px;
}

.group_19 {
  line-height: 36px;
  text-align: center;
}

.text_50 {
  color: #ffffff;
  font-size: 28px;
  font-family: 'Albbr';
  line-height: 36px;
}

.text_51 {
  color: #ffffff;
  font-size: 28px;
  font-family: 'Albbr';
  line-height: 36px;
}

.text_52 {
  color: #ffffff;
  font-size: 28px;
  font-family: 'Albbr';
  line-height: 36px;
}

.space-x-39>*:not(:first-child) {
  margin-left: 39px;
}

.group_20 {
  margin-top: 72px;
}

.section_15 {
  background-color: #ffffff;
  width: 100px;
  height: 6px;
}

.section_16 {
  background-color: #ffffff5c;
  width: 100px;
  height: 6px;
}

.text_53 {
  margin-top: 166px;
  align-self: center;
  color: #000;
  font-size: 42px;
  font-family: OPPOSans;
  line-height: 41px;
}

.group_21 {
  margin-top: 64px;
  align-self: center;
  width: 633px;
}

.group_22 {
  padding-top: 388px;
  position: relative;
}

.image_6 {
  width: 633px;
  height: 166px;
}

.image_7 {
  width: 460px;
  height: 500px;
  position: absolute;
  right: 69px;
  top: 0;
}

.group_23 {
  margin-top: 19px;
  padding: 0 147px;
}

.text_54 {
  color: #000;
  font-size: 32px;
  font-family: OPPOSans;
  line-height: 30px;
}

.text_55 {
  margin-bottom: 3px;
  color: #000;
  font-size: 32px;
  font-family: OPPOSans;
  line-height: 30px;
}

.text_56 {
  margin-right: 254px;
  margin-top: 35px;
  align-self: flex-end;
  color: #7ac0ff;
  font-size: 28px;
  font-family: OPPOSans;
  line-height: 28px;
}

.equal-division {
  margin-top: 40px;
  align-self: center;
  width: 336px;
}

.equal-division-item {
  width: 51px;
  height: 51px;
}

.group_24 {
  margin-top: 44px;
  align-self: center;
  line-height: 36px;
  text-align: center;
}

.text_57 {
  color: #000;
  font-size: 24px;
  font-family: OPPOSans;
  line-height: 36px;
}

.text_58 {
  color: #000;
  font-size: 24px;
  font-family: OPPOSans;
  line-height: 36px;
}

.button {
  margin-top: 47px;
  padding: 27px 0 26px;
  align-self: center;
  border-radius: 46px;
  width: 332px;
  border: solid 4px #0b0306;
}

.text_59 {
  color: #000;
  font-size: 32px;
  font-family: OPPOSans;
  line-height: 32px;
}

.group_25 {
  margin-top: 98px;
  height: 2402px;
}

.image_12 {
  width: 750px;
  height: 2403px;
}
</style>